import { cn } from 'src/shadcn/lib/utils';
import { LogoWithIcon } from 'src/Components/Landing/LogoWithIcon';

export default function Custom404() {
  return (
    <div className='flex h-[100vh] items-center justify-center'>
      <div className='flex flex-col'>
        <LogoWithIcon
          className={cn(
            'mx-auto mb-7 flex h-[40px] cursor-pointer hover:opacity-70'
          )}
        />
        <h1>
          <span className='text-lg font-bold'>Oops!</span> Page not found.
        </h1>
      </div>
    </div>
  );
}
